import React from 'react';
import "../css/styles.css";
import RP from "../images/RP.png";

const socials = [
    {
        id: 1,
        title: 'Email',
        handle: 'hello@davegulati.com',
        url: 'mailto:hello@ruhipelia.com',
        faIconCode: 'far fa-envelope',
    },
    {
        id: 2,
        title: 'LinkedIn',
        handle: 'linkedin.com/in/ruhipelia',
        url: 'https://www.linkedin.com/in/ruhipelia/',
        faIconCode: 'fab fa-linkedin-in',
    }
];

const Home = () => {
    return (
        <div className="page-container">
            <div className="container">
                <div className="text-center mb-2">
                    <img src={RP} className="RP" />
                </div>
                <h1 className="name display-1 text-center mb-0">Ruhi Pelia</h1>
                <h2 className='text-center text-uppercase fw-light'>Software Engineer / <span className='text-lowercase fs-5 fw-normal'>Coffee addict</span></h2>
                <div className="socials-container mt-3">
                    {socials.map((item) => {
                        return (
                            <a key={item.id} className={`social-item ${item.faIconCode}`} href={item.url} target='_blank' rel='noopener noreferrer' title={item.title}></a>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default Home